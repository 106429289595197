// ------------------------------------------------------------
// Alerts
// ------------------------------------------------------------

.alert {
	padding: 15px;
	padding-left: 40px;
	overflow: hidden;
	display: block;
	position: relative;
	@include font-size($para);
	//box-shadow: 0 0 50px 0 rgba(#000, 0.15);
	.form & {
		margin-top: $internal-spacing;
	}
	.ico {
		width: 18px;
		height: 18px;
		background-size: 18px 18px;
		display: inline-block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 15px;
		margin-top: -1px;
	}
}

.alert--notice {
	color: darken($warning, 15%);
	border-left: 3px solid $warning;
}

.alert--error {
	@extend .alert--notice;
	border-left: 3px solid $error;
	color: $error;
}

.alert--success {
	@extend .alert--notice;
	border-left: 3px solid $success;
	color: $success;
}
