// ------------------------------------------------------------
// Navigation
// ------------------------------------------------------------

// ------------------------------------------------------------
// Primary navigation Toggle
// ------------------------------------------------------------

.navigation-primary-toggle {
	display: none;
	overflow: hidden;
	width: 44px;
	height: 42px;
	position: absolute;
	top: 22px; // Tweak as needed
	right: $internal-spacing;
	&:before,
	&:after {
		content: "";
		display: block;
		overflow: hidden;
		height: 2px;
		width: 44px;
		background-color: $nav-toggle-color;
		position: absolute;
		top: 12px;
	}
	&:after {
		top: auto;
		bottom: 12px;
	}
	span {
		height: 2px;
		width: 44px;
		background-color: $nav-toggle-color;
		text-indent: -999em;
		display: block;
		position: relative;
	}
	&:focus {
		outline: none;
		span {

		}
	}
	@include respond-to-max($menu-respond) {
		html.js & {
			display: block;
		}
		&--active {
			&:before,
			&:after {
				display: none;
			}
			span {
				background-color: $nav-toggle-color-active;
			}
		}
	}
	@include respond-to($menu-respond) {
		display: none;
		html.js & {
			display: none;
		}
	}
}

// ------------------------------------------------------------
// Primary menu with mobile folding
// ------------------------------------------------------------

.navigation-primary {
	display: block;
	@include respond-to-max($menu-respond) {
		overflow: hidden;
		transition: max-height ease-out 0.35s;
		html.js & {
			//display: none;
			max-height: 0;
			margin-top: 1em;
		}
		&--active {
			html.js & {
				//display: block;
				transition: max-height ease-out 0.5s;
				max-height: 1000px;
			}
		}
	}
	@include respond-to($menu-respond) {
		display: block;
		float: right;
		margin-top: 3.9em;
		html.js & {
			display: block;
		}
	}
	&__item {
		@extend %pie-clearfix;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 1px;
		@include font-size($zeta);
		&:last-child a {
			border-bottom: none;
		}
		@include respond-to($menu-respond) {
			display: inline-block;
			float: left;
			margin-left: 1em;
			&:first-child {
				margin-left: 0;
			}
		}
		@include respond-to($d-vp) {
			margin-left: 2em;
			&:first-child {
				margin-left: 0;
			}
		}
		a {
			display: block;
			position: relative;
			color: $heading-color;
			span {
				font-size: inherit;
				position: relative;
				z-index: 1;
			}
			@include respond-to-max($menu-respond) {
				padding: 0.75em 0;
				border-bottom: $default-border-width solid $heading-color;
			}
			@include respond-to($menu-respond) {
				&:after {
					content: '';
					position: absolute;
					width: 100%;
					transform: scaleX(0);
					height: 3px;
					bottom: -20px;
					left: 0;
					background-color: $action-respond;
					transform-origin: bottom right;
					transition: transform 0.25s ease-out;
				}
			}
			@include link-active-styles {
				&:after {
					transform: scaleX(1);
  					transform-origin: bottom left;
				}
			}
		}
		&--active {
			a {
				&:after {
					transform: scaleX(1);
  					transform-origin: bottom left;
				}
			}
		}
		&--ss-only {
			@include respond-to($menu-respond) {
				display: none;
			}
		}
	}
}

// ------------------------------------------------------------
// Secondary navigation
// used for inpage nav
// ------------------------------------------------------------

.navigation-secondary-toggle {
	position: relative;
	padding: 1em;
	@include font-size($epsilon);
	background-color: #fff;
	display: block;
	width: 100%;
	color: $iati-sunrise;
	outline: none;
	&:before {
		content: "Show";
		margin-right: 5px;
	}
	.ico {
		position: absolute;
		top: 1em;
		right: 1em;
		transition: rotate, ease-out, 0.3s;
	}
	&--active {
		&:before {
			content: "Hide";
		}
		.ico {
			transform: rotate(45deg);
		}
	}
	@include respond-to-max($c-vp) {
		html.js & {
			display: block;
		}
	}
	@include respond-to($c-vp) {
		display: none;
		html.js & {
			display: none;
		}
	}
}

.navigation-secondary {
	display: block;
	border-top: 1px solid $iati-sunrise;
	@include respond-to-max($c-vp) {
		html.js & {
			display: none;
		}
		&--active {
			html.js & {
				display: block;
			}
		}
	}
	&__item {
		margin-top: 1px;
		@include font-size($epsilon);
		a {
			border-left: 2px solid $iati-sunrise;
			padding: 0.75em 1em;
			margin-top: 1px;
			color: $heading-color;
			background-color: #fff;
			display: block;
			transition: all, ease-in-out, 0.3s;
			&:hover {
				border-left-width: 4px;
			}
		}
		&--active a {
			background-color: $iati-sunrise;
			border-left-width: 4px;
			color: #fff;
		}
	}
}

.navigation-secondary__child {
	&__item {
		a {
			background-color: lighten($iati-sunrise, 24%);
			border-left-width: 4px;
			color: $heading-color;
			padding-left: 1.5em;
			&:hover {
				border-left-width: 6px;
			}
		}
		&--active a {
			background-color: lighten($iati-sunrise, 6%);
			border-left-color: lighten($iati-sunrise, 6%);
			color: #fff;
		}
	}
}

.navigation-secondary__third-child {
	&__item {
		a {
			background-color: lighten($iati-sunrise, 20%);
			border-left-width: 6px;
			color: $heading-color;
			padding-left: 2em;
			&:hover {
				border-left-width: 8px;
			}
		}
		&--active a {
			border-left-width: 8px;
		}
	}
}

// ------------------------------------------------------------
// Utility navigation
// ------------------------------------------------------------

.navigation-utility {
	display: none;
	@include respond-to($menu-respond) {
		overflow: hidden;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		background-color: $iati-ash;
		padding: 1em 3em 1em 2em;
	}
}

.navigation-utility__item {
	float: left;
	margin-left: 1.5em;
	@include font-size($zeta);
	&:first-child {
		margin-left: 0;
	}
	a {
		color: $heading-color;
		@include link-active-styles {
			border-bottom: 1px solid $action-respond;
		}
	}
	&--active a {
		border-bottom: 1px solid $action-respond;
	}
}
