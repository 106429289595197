
.download-list {
    @extend .para;
}

.download-list__item {
    &:not(:last-child) {
        margin-bottom: 1px;
    }
}

.download-list__item-link {
    background-color: lighten($iati-wind, 30%);
    border-left: 2px solid $iati-wind;
    display: block;
    padding: 1em 0 0.75em 1em;
    transition: all ease-in-out 0.3s;
    @include link-active-styles {
        border-width: 4px;
		color: $action;
	}
}

.download-list__item-link-heading {
    @include font-size($epsilon);
    color: $base-color;
}

.download-list__item-link-subheading {
    @include font-size($zeta);
    color: $base-color;
    display: block;
}