// ------------------------------------------------------------
// Sections
// ------------------------------------------------------------

.sections {
    margin-bottom: 5em;
}

.section {
    @extend .space-section-top;
    @extend .padd-section-both;
    background-color: #fff;
}

.section-alt {
    @extend .space-section-bottom;
}

.section-alt-top {
    @extend .space-section-top;
}

.section-alt-bottom {
    @extend .space-section-bottom;
}

.section-border {
    @extend .space-section-bottom;
    &:not(:last-child) {
        border-bottom: 3px solid $default-border-color;
        @extend .padd-section-bottom;
    }
}

.section-header-link {
    position: relative;
    overflow: hidden;
    margin-bottom: 3em;
    &__action {
        @include font-size($para);
        border-bottom: 1px solid $action;
    }
    @include respond-to($menu-respond) {
        &__action {
            float: right;
            margin-top: 9px;
        }
        .section__heading {
            float: left;
            width: 75%;
        }
    }
}

.section__heading {
    @extend .type-l;
    @extend .heading;
    line-height: 1.3;
    margin-bottom: 0.75em;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 2em;
        height: 3px;
        background-color: $iati-air;
    }
}

.section__copy {
    @include font-size($para);
    margin-bottom: 1em;
}
