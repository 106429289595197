// ------------------------------------------------------------
// Footer
// ------------------------------------------------------------


.footer {
    background-color: $iati-ocean;
}

.footer__content {
    padding: 4em 0;
    //margin-top: 4em;
    @include respond-to($menu-respond) {
        padding: 6em 0;
        //margin-top: 6em;
    }
}

.footer__meta {
    overflow: hidden;
    border-top: 1px solid lighten($iati-ocean, 10%);
    padding: 2em 0;
}

.footer__heading {
    @extend .heading;
    @include font-size($epsilon);
    margin-bottom: 0.75em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
}


.footer__copy {
    @include font-size($epsilon - 1);
    color: #fff;
    margin-bottom: 0.75em;
}


.footer__list {
    @include font-size($epsilon - 1);
    li {
        display: block;
        margin-bottom: 0.25em;
        a {
            color: #fff;
            text-decoration: underline;
            @include link-active-styles {
                text-decoration: none;
            }
        }
    }
}

.footer__meta-flex {
    @extend %l-flexbox;
    align-items: center;
}

.footer__legal {
    @include font-size($zeta);
    color: #fff;
    display: block;
    @include respond-to($b-vp) {
        display: inline-block;
        float: left;
        margin-top: 0.5em;
    }
}
