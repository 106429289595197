// Referenced in base/libs/_placeholders.scss

// ------------------------------------------------------------
// Local fonts
// ------------------------------------------------------------

// Name / file name (must be the same for each webfont type


// ------------------------------------------------------------
// SVN fonts
// ------------------------------------------------------------

@import url("https://use.typekit.net/esn8rgw.css");
