// ------------------------------------------------------------
// Button styles
// ------------------------------------------------------------

.button {
	background: transparent; // // Reset button and reset elements
	border: none; // Reset button and reset elements
	-webkit-appearance: none;
	// ------- //
	display: inline-block;
	text-align: left;
	padding: 1em;
	@include font-size($eta);
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: 1;
	color: $heading-color;
	background-color: $action-respond;
	//background: linear-gradient(30deg, darken($action-respond, 15%), $action-respond);
	border-bottom: 2px solid rgba(0,0,0,0.25);
	text-decoration: none;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	transition: all, ease-in-out, 0.3s;
	@include link-active-styles {
		color: #fff;
		background-color: $action;
		//background: linear-gradient(30deg, $action-respond, $action-respond);
		color: #fff;
	}
}

// Give actual buttons some love
button.button, input.button {
	//width: 100%;
	height: $form-item-height;
	.footer & {
		display: inline-block;
		width: auto;
		margin-top: 0.5em;
		@include respond-to($d-vp) {
			height: $form-item-height;
			margin-top: 0;
		}
	}
}

// ------------------------------------------------------------
// Accessible labels
// ------------------------------------------------------------

.button-a11y {
	position: relative;
	overflow: hidden;
	span {
		top: -10em;
		position: absolute;
		display: inline-block;
	}
	&:after {
		 content: attr(title);
		 display: inline-block;
		 color: inherit;
	}
}

// We want this available as a BEM to extend .button, as well as being stand alone
.button--a11y {
	@extend .button-a11y;
}

// ------------------------------------------------------------
// Variation styles
// ------------------------------------------------------------

.button--block {
	display: block;
	width: 100%;
	text-align: center;
}

.button--secondary {
	color: #fff;
	background-color: $action;
	//background: linear-gradient(30deg, $action, $iati-sunset);
	@include link-active-styles {
		color: $heading-color;
		background-color: $action-respond;
		//background: linear-gradient(30deg, $iati-sunset, $iati-sunset);
	}
}

.button--large {
	@include font-size($epsilon);
	text-align: center;
}

.button--disabled {
	opacity: 0.5;
	cursor: not-allowed;
	filter: grayscale(100%);
}
