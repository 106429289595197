// Basic responsive videos
// ------------------------------------------------------------

.media-video-wrapper,
.media-wrapper {
	position: relative;
	@include maintain-ratio(16 9);
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.is-typeset & {
		margin: 2em 0;
	}
}

.media-wrapper--4-3 {
	@include maintain-ratio(4 3);
}

.media-figure {
	.is-typeset & {
		margin: 2em 0;
	}
	img {
		display: block;
		margin-bottom: 0.5em;
		width: 100%;
		height: auto;
	}
	&__caption {
		@include font-size($para - 2);
		display: block;
		padding: 0.5em 1em;
		border-left: 2px solid $iati-sunrise;
		a {
			border-bottom: 2px solid #06dbe4;
		}
		p {
			margin-bottom: 0px;
		}
	}
	&--alignleft {
		width: 50%;
		float: left;
		padding-right: 5%;
		padding-bottom: 2%;
		.is-typeset & {
			margin: 0;
		}
	}
	&--alignright {
		width: 50%;
		float: right;
		padding-left: 5%;
		padding-bottom: 2%;
		.is-typeset & {
			margin: 0;
		}
	}
	&--center {
		@include respond-to($c-vp) {
			width: 60%;
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}
}

// Bad
// ------------------------------------------------------------

#eventbrite-widget-container-33395177876 {
	height: 285px !important;
	iframe {
		height: 285px !important;
	}
}
