@import "core/reset";
@import "core/settings";
@import "core/mixins";
@import "core/placeholders";

/* ------------------------------------------------------------ */

html {
	font-size: 10px;
	font-size: 62.5%;
	// Mobile (iPhone) can sometimes be a git about font resizing. Adding to a max-media query solves it
	@include respond-to-max($device-safezone-vp) {
		-webkit-text-size-adjust: 100%;
	}
}

body {
	@extend %font-body;
	@include leading($base-font-size);
	background: $body-color;
	color: $base-color;
}

/* :: Granular and Modular styles */
/* ------------------------------------------------------------ */

// Import core modules that pertain to styling
@import "core/base";
@import "core/forms";
@import "core/fonts";
@import "core/typography";

// Import all modules
@import "modules/*";

// Import all items required further up the stack
@import "modules/priority-extends/*";


/* ::Print */
/* ------------------------------------------------------------ */

@media print {
	body {
		font-size: percentage(10 / 16);
		color: #000!important;
		background-color: #fff!important;
	}
	*[role="navigation"],
	*[role="footer"] {
		display: none!important;
	}
}
