// ------------------------------------------------------------
// Pagination
// ------------------------------------------------------------

.pagination {
    @extend %pie-clearfix;
    display: block;
    margin-top: 5em;
    width: 100%;
    clear: both;
    text-align: center;
}

.pagination__item {
    color: $heading-color;
    @include font-size($zeta);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-right: 5px;
    display: none;
    a {
        padding: 0.75em 1.25em;
        transition: all, ease-in-out, 0.3s;
        border-bottom: 2px solid $default-border-color;
        display: block;
        &:hover {
            color: $heading-color;
            transform: translateY(-2px);
            border-color: $action-respond;
        }
    }
    @include respond-to($c-vp) {
        display: inline-block;
    }
}

.pagination__item--big {
    @include font-size($epsilon);
}

.pagination__item--next, .pagination__item--prev {
    margin-right: 0;
    display: block;
    a {
        background-color: #fff;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
        border-bottom: 0;
    }
}

.pagination__item--prev {
    float: left;
}

.pagination__item--next {
    float: right;
}
