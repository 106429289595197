.timeline {
    margin-top: 3em;
    margin-bottom: 5em;
    position: relative;
    &:after {
        background-color: $iati-air;
        content: '';
        display: block;
        height: 100%;
        margin-left: -1px;
        position: absolute;
        top: 0%;
        left: 50%;
        width: 2px;
        z-index: -1;
    }
    @include respond-to($menu-respond) {
        margin-top: 4em;
    }
}

.timeline-row {
    @include respond-to($menu-respond) {
        display: flex;
    }
}

.timeline__date {
    margin-bottom: 2em;
    position: relative;
    @include respond-to($menu-respond) {
        margin-bottom: 4em;
        width: 50%;
        &:after {
            background-color: #f6f6f6;
            border: 2px solid $iati-air;
            border-radius: 100%;
            content: '';
            display: block;
            height: 10px;
            margin-top: -6px;
            position: absolute;
            top: 50%;
            width: 10px;
        }
    }
}

.timeline__date--left {
    @include respond-to($menu-respond) {
        &:after {
            right: -5px;
        }
    }
}

.timeline__date--right {
    @include respond-to($menu-respond) {
        transform: translateY(11em);
        &:after {
            left: -5px;
        }
    }
}

.timeline__date--left {
    .timeline__date-panel {
        &:before {
            border-width: 10px 0 10px 10px;
            border-color: transparent transparent transparent #fff;
            right: -10px;
        }
    }
}

.timeline__date--right {
    .timeline__date-panel {
        &:before {
            border-width: 10px 10px 10px 0;
            border-color: transparent #ffffff transparent transparent;
            left: -10px;
        }
    }
}

.timeline__date-panel {
    background-color: #fff;
    box-shadow: 0 0 30px -10px rgba(0, 0, 0, 0.2);
    position: relative;
    @include respond-to($menu-respond) {
        transition: all 0.3s ease-in-out;
        width: 90%;
        &:before {
            border-style: solid;
            content: '';
            display: block;
            height: 0;
            margin-top: -10px;
            position: absolute;
            top: 50%;
            width: 0;
        }
        &:hover {
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
            transform: scale(1.03);
        }
        .timeline__date--right & {
            float: right;
        }
    }
}

.timeline__date-heading {
    @include font-size($epsilon);
    background-color: $iati-air;
    letter-spacing: 1px;
    padding: 30px 15px 15px;
    text-transform: uppercase;
}

.timeline__date-copy {
    @include font-size($zeta);
    padding: 15px;
}
