// ------------------------------------------------------------
// Modules
// prefix with m-
// ------------------------------------------------------------

.m-additional {
    background-color: #fff;
    box-shadow: 0 0 50px 0 rgba(#000, 0.15);
    margin-top: 4em;
    padding: 4em;
}

.m-additional--no-trailing {
    margin-top: 0;
}

.m-additional__heading {
    @include font-size($delta);
    margin-top: 0;
    margin-bottom: 1em;
}

.m-additional__copy {
    margin-bottom: 1em;
}

.m-additional__list {
    margin-bottom: 0;
}

.m-blocks {
    @include respond-to($menu-respond) {
        @supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(auto-fill, 47.5%);
			grid-gap: 5%;
		}
        margin-top: 4em;
        margin-bottom: 5em;
    }
}

.m-block {
    background-color: #fff;
    border-left: 2px solid $iati-air;
    box-shadow: 0 0 50px 0 rgba(#000, 0.1);
    padding: 2em;
    margin-bottom: 2em;
    display: block;
    .button {
        align-self: flex-end;
        margin-top: auto;
        margin-right: auto;
    }
    @include respond-to($menu-respond) {
        width: 47.5%;
        float: left;
        margin-left: 5%;
        &:nth-child(2n+1) {
            margin-left: 0;
            clear: left;
        }
        @supports (display: grid) {
			width: 100%;
            margin-left: auto;
            clear: none;
            float: none;
		}
    }
}

.m-block__heading {
    @extend .heading;
    @include font-size($delta);
    margin-bottom: 0.5em;
}

.m-block__copy {
    @include font-size($para);
    margin-bottom: 1em;
}

.m-placeholder {
    background-color: $default-border-color;
    height: 400px;
}

.m-placeholder--landscape {
    height: 200px;
}

.m-placeholder--logo {
    height: 400px;
}

.m-quote {
    margin-bottom: 1em;
    @include respond-to($menu-respond) {
        display: flex;
        margin-bottom: 4em;
    }
    p {
        margin-bottom: 0.75em;
    }
}

.m-quote__img {
    background-color: $iati-ash;
    @include respond-to($menu-respond) {
        height: 130px;
        margin-right: 5%;
        width: 20%;
    }
}

.m-quote__quote {
    align-self: flex-start;
    @include respond-to($menu-respond) {
        margin-left: auto;
    }
}

// ------------------------------------------------------------
// Search
// ------------------------------------------------------------

.m-search {
    background-color: $iati-ash;
    padding: $internal-spacing 0;
    margin-top: -5em;
    margin-bottom: 5em;
    //box-shadow: 0 0 50px 0 rgba(#000, 0.15);
    .form-field {
        width: 75%;
        display: inline-block;
        float: left;
        input {
            border: none;
        }
        +.form-field {
            width: 24%;
            float: right;
            margin-top: 29px;
        }
    }
}

// ------------------------------------------------------------
// Styled block list
// ------------------------------------------------------------

.m-block-list {
    @extend %pie-clearfix;
    @include font-size($para);
    li {
        padding: 1em;
        text-align: center;
        border-bottom: 3px solid $default-border-color;
        margin-bottom: 1em;
        @include respond-to($c-vp) {
            display: inline-block;
            float: left;
            width: 32%;
            margin-left: 2%;
            margin-bottom: 0;
            &:nth-child(3n+1) {
                margin-left: 0;
                clear: left;
            }
        }
    }
}
