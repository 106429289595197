// ------------------------------------------------------------
// Sections
// Relates to the spacing and padding of whole sections
// ------------------------------------------------------------

// Padding
.padd-section-top 		{ padding-top: $internal-spacing*1.5; }
.padd-section-bottom 	{ padding-bottom: $internal-spacing*1.5; }
.padd-section-both 		{ @extend .padd-section-top; @extend .padd-section-bottom; }


// Spacing
.space-section-top 		{ margin-top: $internal-spacing*1.5; }
.space-section-bottom 	{ margin-bottom: $internal-spacing*1.5; }
.space-section-both 	{ @extend .space-section-top; @extend .space-section-bottom; }
