// -----------------------------------------------------------
// Cards
// -----------------------------------------------------------

.card {
    position: relative;
    background-color: #fff;
    padding: 2em;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    &--stacked {
        position: relative;
        &:after {
            z-index: -1;
            content: "";
            position: absolute;
            bottom: -10px;
            right: -10px;
            width: 100%;
            height: 100%;
            background-color: #fff;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
        }
    }
    &--step{
        background-color: $iati-ocean;
        min-height: 150px;
        height: 100%;
        @supports (display: grid) {
            min-height: auto;
            height: auto;
        }
    }
    &--guidance {
        background-color: $iati-air;
        &:after {
            background-color: $iati-ocean;
        }
    }
    &--knowledge {
        background-color: $iati-ocean;
    }
    &--alt {
        position: relative;
        border-top: 5px solid $iati-land;
        min-height: 300px;
        .button {
            position: absolute;
            bottom: 2em;
        }
    }
}


.card__header {
    padding: 2em;
}

.card__body {
    padding: 2em;
    .card__list {
        margin-top: 0;
    }
}

.card__heading {
    @extend .heading;
    @include font-size($gamma);
    line-height: 1.3;
    display: block;
    a span {
        display: inline;
        border-bottom: 2px solid $action-respond;
    }
    .card--guidance & {
        a {
            color: $action;
            span {
                border-color: $action;
                transition: all, ease-in-out, 0.3s;
            }
            &:hover {
                span {
                    border-color: #fff;
                }
            }
        }
    }
    .card--knowledge & {
        a {
            color: #fff;
            span {
                border-color: #fff;
                transition: all, ease-in-out, 0.3s;
            }
            &:hover {
                span {
                    border-color: $action-respond;
                }
            }
        }
    }
}

.card__list {
    margin-top: 1.5em;
    h2 {
      @extend .heading;
      @include font-size($gamma);
      line-height: 1.3;
      display: block;
    }
    li {
        @include font-size($para);
        border-bottom: 1px solid $default-border-color;
        padding: 0.75em 0 0.5em 0;
        &:last-child {
            border-bottom: 0;
        }
        a {
            transition: all, ease-in-out, 0.3s;
            &:hover {
                padding-left: 0.5em;
            }
        }
    }
    &--alt {
        margin-top: 2em;
        li:first-child {
            padding-bottom: 0;
            border: 0;
            padding-left: 0;
            margin-bottom: 0.5em;
        }
        li {
            border-left: 2px solid $default-border-color;
            padding-left: 1em;
            border-bottom: 0;
            padding-bottom: 0.25em;
            padding-top: 0;
            a {
                text-decoration: underline;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
    .card__list-child {
        margin-left: 1em;
    }
}

.card__excerpt {
    @include font-size($para);
    margin-top: 1.5em;

    &--truncated {
        @extend .card__excerpt;
        display: none;
    }

    @include respond-to-max (500px) {
        /* firefox */
        display: none;
        
        &--truncated {
            display: block;
        }
    }

    @supports (-webkit-appearance:none) {
        @include respond-to-max (1050px) {
            /* chrome + safari */
            display: none;
            
            &--truncated {
                display: block;
            }
        }
    }
}

.card__flag {
    @include font-size($eta);
    float: right;
    margin-left: 2em;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 0.5em;
    display: block;
    border: 1px solid $default-border-color;
    padding: 0.5em 0.75em 0.25em 0.75em;
    .card--guidance & {
        color: $heading-color;
        border-color: $heading-color;
    }
}

.card__step {
    text-align: center;
    width: 100%;
    .ico {
        display: inline-block;
        top: -5px;
    }
    @include respond-to($c-vp) {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        .ico {
            display: block;
            margin-top: 2em;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.card__step__text {
    @include font-size($gamma);
    color: #fff;
    display: inline-block;
    @include respond-to($c-vp) {
        display: block;
    }
}
