// ------------------------------------------------------------
// Header
// ------------------------------------------------------------

.header {
	overflow: hidden;
	position: relative;
	z-index: 5;
	max-width: $max-grid;
	margin-left: $internal-spacing;
	margin-right: $internal-spacing;
	margin-top: 2em;
	margin-bottom: 5em;
	background-color: #fff;
	padding: 2em;
	padding-bottom: 1.5em;
	box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
	@include respond-to($menu-respond) {
		margin-top: 4em;
		padding: 2em 3em;
		margin-bottom: 0;
	}
	@include respond-to($max-vp) {
		margin-left: auto;
		margin-right: auto;
	}
}
