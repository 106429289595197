// ------------------------------------------------------------
// Heroes
// ------------------------------------------------------------

.hero {
    // background: linear-gradient(180deg, $iati-ocean, $iati-ocean, #F6F6F6);
    background: linear-gradient(0deg, darken($iati-ocean, 4%) 4%, $iati-ocean 20%);
    margin-bottom: 5em;
    padding-top: 15em;
    padding-bottom: 5em;
    margin-top: -15.5em;
    position: relative;
    @include respond-to-max($menu-respond) {
        &:before {
            content: "";
            position: absolute;
            background-color: $iati-ocean;
            width: 100%;
            height: 100%;
            top: -100%;
            z-index: 1;
        }
    }
    &--image {
        border-bottom: 0;
        padding-bottom: 5em;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: $iati-ocean;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0,0,0,0.5), rgba(0,0,0,0));
        }
    }
    &--minor {
        border-bottom: 0;
        background-color: $iati-ocean;
        padding-bottom: 5em;
    }
    @include respond-to($c-vp) {
        overflow: hidden;
        padding-top: 20em;
        // margin-bottom: 8em;
        &--image {
            padding-top: 25em;
            margin-bottom: 5em;
        }
        &--minor {
            position: relative;
            &:after {
                content: "";
                background-image: url(../img/marque-white.png);
                background-size: 70%;
                background-repeat: no-repeat;
                background-position: top right;
                opacity: 0.25;
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                width: 100%;
                height: 100%;
            }

        }
    }
    @include respond-to($menu-respond) {
        padding-top: 24em;
        margin-top: -19em;
        &--image {
            padding-top: 28em;
            padding-bottom: 6em;
            background-attachment: fixed;
        }
    }
    @include respond-to($d-vp) {
        padding-top: 28em;
        &--minor {
            padding-top: 24em;
        }
    }
}

.hero__caption {
    position: relative;
    z-index: 3;
    border-left: 5px solid $iati-air;
    padding-left: 1.5em;
    @include respond-to($c-vp) {
        max-width: 50%;
        .hero--image &,
        .max-meter & {
            max-width: 100%;
        }
    }
}

.hero__heading {
    @include font-size($beta + 3);
    @extend .heading;
    @include respond-to($menu-respond) {
        @include font-size($alpha);
    }
    color: #fff;
    // color: $iati-ocean;
    line-height: 1;
    word-wrap: break-word;
    word-break: keep-all;
    .hero--image &, .hero--minor & {
        color: #fff;
    }
}

.hero__subheading {
    @include font-size($delta);
    color: #fff;
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.hero__excerpt {
    @include font-size($para);
    color: #fff;
    margin-top: 1em;
    .hero--minor & {
        color: #fff;
    }
}

.hero__type {
    @extend .heading;
    @include font-size($para);
    color: #fff;
    display: block;
    text-transform: uppercase;
}

.hero__emblem {
    display: none;
    @include respond-to($c-vp) {
        display: block;
        position: absolute;
        z-index: 0;
        top: -22em;
        right: $internal-spacing;
        img {
            max-width: 100%;
        }
        html.no-cssmask & {
            background-image: url(../img/marque-white.png);
            background-size: 43%;
            background-repeat: no-repeat;
            background-position: top right;
            display: block;
            width: 100%;
            height: 1000%;
            opacity: 0.5;
        }
    }
    @include respond-to($menu-respond) {
        top: -15em;
    }
    @include respond-to($d-vp) {
        top: -24em;
    }
}

.hero__emblem__mask {
    mask-image: url(../img/marque-white.png);
    html.svg & {
        mask-image: url(../svg/source/marque-white-animated.svg);
    }
    mask-mode: alpha;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: top right;
    width: auto;
    @include respond-to($c-vp) {
        height: 360px;
    }
    @include respond-to($d-vp) {
        height: 440px;
    }
    html.no-cssmask & {
        display: none;
    }
}
